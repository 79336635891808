import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Box,
    Typography,
    Button,
    CircularProgress,
    Alert,
    Snackbar,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useAssociateBrokerWithEmployersMutation, useUnassociateBrokerFromEmployersMutation } from '../../reducers/enrollmentApiSlice';
import AuthTokenService from "../../services/AuthTokenService";
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SingleEmployerSelector from "../Employer/SingleEmployerSelector";

const AssociateEmployersWithBrokerModal = ({ open, onClose, agencyCode, brokerEmail, onSuccess }) => {
    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [associateBrokerWithEmployers, { isLoading: isAssociating, isSuccess: isAssociateSuccess, isError: isAssociateError, error: associateError }] = useAssociateBrokerWithEmployersMutation();
    const [unassociateBrokerFromEmployers, { isLoading: isUnassociating, isSuccess: isUnassociateSuccess, isError: isUnassociateError, error: unassociateError }] = useUnassociateBrokerFromEmployersMutation();
    const { user } = AuthTokenService.getAuthInfo();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleEmployerSelect = (employer) => {
        setSelectedEmployer(employer);
    };

    const handleAssociate = async () => {
        try {
            const dto = {
                agencyCode,
                brokerEmail,
                employerCodes: selectedEmployer === null ? [] : [selectedEmployer.employerCode]
            };
            await associateBrokerWithEmployers(dto).unwrap();
            setSnackbar({ open: true, message: 'Employers associated successfully', severity: 'success' });
            onSuccess();
        } catch (err) {
            setSnackbar({ open: true, message: `Error associating employers: ${err.message}`, severity: 'error' });
        }
    };

    const handleUnassociate = async () => {
        try {
            const dto = {
                agencyCode,
                brokerEmail,
                employerCodes: selectedEmployer === null ? [] : [selectedEmployer.employerCode]
            };
            await unassociateBrokerFromEmployers(dto).unwrap();
            setSnackbar({ open: true, message: 'Employers unassociated successfully', severity: 'success' });
            onSuccess();
        } catch (err) {
            setSnackbar({ open: true, message: `Error unassociating employers: ${err.message}`, severity: 'error' });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="associate-employers-modal"
                aria-describedby="associate-employers-description"
            >
                <Box sx={{
                    p: 4,
                    backgroundColor: 'background.paper',
                    margin: 'auto',
                    width: fullScreen ? '100%' : 500,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    mt: fullScreen ? 0 : 8,
                    borderRadius: fullScreen ? 0 : 2,
                    boxShadow: 24,
                }}>
                    <Typography id="associate-employers-modal" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Manage Employer Associations for {brokerEmail}
                    </Typography>
                    <SingleEmployerSelector onEmployerSelect={handleEmployerSelect} email={user} />

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            onClick={handleUnassociate}
                            disabled={isUnassociating || selectedEmployer === null}
                            startIcon={isUnassociating ? <CircularProgress size={20} /> : <LinkOffIcon />}
                            aria-label="Unassociate Employers"
                        >
                            Unassociate
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAssociate}
                            disabled={isAssociating || selectedEmployer === null}
                            startIcon={isAssociating ? <CircularProgress size={20} /> : <LinkIcon />}
                            aria-label="Associate Employers"
                        >
                            Associate
                        </Button>
                    </Box>
                    <Button
                        onClick={onClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                        aria-label="Close modal"
                    >
                        <CloseIcon />
                    </Button>
                </Box>
            </Modal>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

AssociateEmployersWithBrokerModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    agencyCode: PropTypes.string.isRequired,
    brokerEmail: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default AssociateEmployersWithBrokerModal;