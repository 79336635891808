
export const benefitEnrollmentEndpoints = (builder) => ({
    enrollWithDependents: builder.mutation({
        query: (benefitEnrollmentDto) => ({
            url: '/BenefitEnrollment/EnrollWithDependents',
            method: 'POST',
            data: benefitEnrollmentDto
        }),
    }),
    getEnrollmentsByEmail: builder.query({
        query: (accountEmail) => ({
            url: `/BenefitEnrollment/GetEnrollmentsByEmail?accountEmail=${encodeURIComponent(accountEmail)}`,
            method: 'GET',
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response ?? [];
        },
    }),

    uploadEnrollmentSignature: builder.mutation({
        query: (uploadEnrollmentSignatureDto) => ({
            url: '/BenefitEnrollment/UploadEnrollmentSignature',
            method: 'POST',
            data: uploadEnrollmentSignatureDto
        }),
    }),

    getEnrollmentSignatureBySignatureId: builder.query({
        query: ({accountEmail, signatureId}) => ({
            url: `/BenefitEnrollment/GetEnrollmentSignatureBySignatureIdAsync?accountEmail=${encodeURIComponent(accountEmail)}&signatureId=${encodeURIComponent(signatureId)}`,
            method: 'GET',
        }),
        transformResponse: (response) => {
            return response ?? null;
        },
    }),

    getEnrollmentSignatureByEnrollmentId: builder.query({
        query: ({accountEmail, enrollmentId}) => ({
            url: `/BenefitEnrollment/GetEnrollmentSignatureByEnrollmentId?accountEmail=${encodeURIComponent(accountEmail)}&enrollmentId=${encodeURIComponent(enrollmentId)}`,
            method: 'GET',
        }),
        transformResponse: (response) => {
            return response ?? null;
        },
    }),

    updateEnrollmentByEmployeeBenefitEnrollmentId: builder.mutation({
        query: (updateEnrollmentByEmployeeBenefitEnrollmentIdDto) => ({
            url: '/BenefitEnrollment/UpdateEnrollmentByEmployeeBenefitEnrollmentId',
            method: 'POST',
            data: updateEnrollmentByEmployeeBenefitEnrollmentIdDto
        }),
    }),

});
