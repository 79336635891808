import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {Typography, Pagination, Alert, useTheme, Stack, Grid} from '@mui/material';
import { useGetEnrollmentsByEmailQuery } from '../../reducers/enrollmentApiSlice';
import { Policy } from '../../types/policy';
import LoadingSpinner from "../LoadingSpinner";
import PlanSelectionPaper from "../PlanSelection/PlanSelectionPaper";
import {Container} from "@mui/material/";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import dayjs from "dayjs";
import {BenefitEnrollmentView} from "./BenefitEnrollmentView";
import {BenefitEnrollmentComponentFilter} from "./BenefitEnrollmentFilter";

const BenefitEnrollmentComponent = () => {
    const { email } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditable, setIsEditable] = useState(false);
    const [validYears, setValidYears] = useState([dayjs().year()]);
    const itemsPerPage = 6;

    // Filters
    const [selectedDate, setSelectedDate] = useState(null);

    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const { data: enrollments, error, isLoading, refetch } = useGetEnrollmentsByEmailQuery(email);

    useEffect(() => {
        const editableRoles = Policy?.userHasSufficientPermissions(Policy?.GlobalAdminAgentEmployerAdminBroker);
        setIsEditable(editableRoles);
    }, []);

    useEffect(() => {
        if (!isLoading && enrollments) {
            const validYearsSet = new Set();
            for(const enrollment of enrollments) {
                validYearsSet.add(dayjs(enrollment.enrollmentTimestamp).year());
            }
            setValidYears([...validYearsSet]);
        }
    }, [enrollments]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    // Sort Pass
    // Sort the enrollments by the date in descending order.
    const sortedEnrollments = enrollments != null ? [...enrollments].sort( (enrollment1, enrollment2) => {
        return dayjs(enrollment1.enrollmentTimestamp).isBefore(dayjs(enrollment2.enrollmentTimestamp));
    }) : [];

    // Filter Pass
    const filteredEnrollments = sortedEnrollments.filter(enrollment => {
        const year = dayjs(enrollment.enrollmentTimestamp).year();
        const enrollmentDateIsValid = validYears.includes(year);

        if (selectedDate != null) {
            return selectedDate.year() === year && enrollmentDateIsValid
        }
        return enrollmentDateIsValid;
    });

    // Pagination Pass
    const paginatedEnrollments = filteredEnrollments?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) || [];

    const handleDateFilterChange = (newDate) => {
        if (newDate == null) {
            // Set to null on load or when clearing the filter.
            setSelectedDate(null);
        } else {
            setSelectedDate(dayjs(newDate));
        }
        setCurrentPage(1);
    }

    return (
        <Container maxWidth="xl" sx={{ p: 3, bgcolor: theme.palette.background.default }}>
            <Typography variant="h2" sx={{ mt: 2, mb: 4 }} gutterBottom color="primary">
                {translate('Enrolled Benefits')}
            </Typography>

            {isLoading && <LoadingSpinner />}
            {error && <Alert severity="error">{translate('Failed to load data. Please try again later.')}</Alert>}
            {enrollments != null ? (
                <Grid container spacing={4} sx={{ display: 'flex', alignItems: 'stretch' }} >
                    <Grid item xs={12} key={'benefit-enrollment-filter-grid'}>
                        <BenefitEnrollmentComponentFilter
                            selectedDate={selectedDate}
                            validYears={validYears}
                            onDateChange={handleDateFilterChange}
                        />
                    </Grid>
                    {paginatedEnrollments.map((enrollment, index) => {
                        return (<BenefitEnrollmentView
                            enrollment={enrollment}
                            isEditable={isEditable}
                            key={index}
                            userEmail={email ?? ''}
                            refetchAll={refetch}
                        />);
                    })
                    }

                </Grid>
            ) : null}

            {filteredEnrollments && filteredEnrollments.length > itemsPerPage ? (
                <Stack spacing={2} alignItems="center" mt={4}>
                    <Pagination
                        count={Math.ceil(filteredEnrollments.length / itemsPerPage)}
                        page={Math.max(1, currentPage)}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </Stack>
            ) : null }

            {
                filteredEnrollments.length <= 0 && !isLoading ? (
                    <PlanSelectionPaper additionalStyles={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10, mx: 'auto', minHeight: '10vh', maxHeight: '40vh', aspectRatio: '16/9' }}>
                        <Typography variant="h2" sx={{ mt: 2, mb: 4 }} gutterBottom color="primary">{translate('No enrollments found.')}</Typography>
                    </PlanSelectionPaper>
                ) : null
            }

        </Container>
    );
};

export default BenefitEnrollmentComponent;
