import {Box, Button, Chip, Divider, Grid, Modal, Stack, Typography, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import React, {useState} from "react";
import {useGetLocalPlanSummaryDocuments} from "../../hooks/useGetLocalPlanSummaryDocuments";
import PlanSelectionPaper from "../PlanSelection/PlanSelectionPaper";
import PlanSelectionSummaryButton from "../PlanSelection/PlanSelectionSummaryButton";
import {PlanSummaryModal, PlanSummaryModalWithUrls} from "../Plan/PlanSummaryModal";
import {StyledBackdrop} from "../Modal/styled";
import ModalContent from "../Modal/ModalContent";
import {EditBenefitEnrollment} from "./EditBenefitEnrollment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const BenefitEnrollmentView = ({enrollment, isEditable, userEmail, refetchAll}) => {
    useTheme();
    const { translate } = useCustomTranslation();

    let planType = enrollment?.additionalInfo?.type ?? null;
    const hasPlanId = enrollment?.carrierPlan.planId !== null && enrollment?.carrierPlan.planId !== 0;
    if (hasPlanId) {
        planType = "local";
    }

    const [isPlanDocumentModalOpen, setIsPlanDocumentModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const {isFetching, documents, fetchDocuments} = useGetLocalPlanSummaryDocuments(hasPlanId ? enrollment.carrierPlan.planId : null);

    let planSummaryLink = null;
    switch (planType) {
        case "ebtoolkit": {
            planSummaryLink = enrollment?.additionalInfo?.plan?.urlForBenefitSummaryCoverage ?? null;
            break;
        }
        case "hsa": {
            // FIXME: Include the hsa plan summary link in additionalInfo.
        }
    }

    const formatDate = (dateString) => {
        return dateString ? new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }) : 'N/A';
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const handleEditedEnrollment = async () => {
        await refetchAll();
        // handleEditModalClose();
    };

    return (
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }} key={`enrollment-id-${enrollment.id}`}>
            <PlanSelectionPaper additionalStyles={{ width: '100%' }}>
                <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: '2rem', height: '100%'}}>
                    <Stack spacing={1}>

                        {
                            isEditable ? (  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={ () => {setIsEditModalOpen(true)} }
                                >
                                    {translate('Edit')}
                                </Button>

                                <Modal open={isEditModalOpen} onClose={() => { handleEditModalClose() }} BackdropComponent={StyledBackdrop}>
                                    <ModalContent sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 'min(90%, 600px)',
                                        minHeight: 'min(500px, 60vh)',
                                        maxHeight: '70vh',
                                        boxShadow: 24,
                                        borderRadius: 2,
                                        p: 4,
                                        overflowY: 'auto'
                                    }}>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <IconButton
                                                size="medium"
                                                aria-label="close"
                                                color="inherit"
                                                onClick={ () => { setIsEditModalOpen(false)  }}
                                            >
                                                <CloseIcon fontSize="medium" />
                                            </IconButton>
                                        </Box>

                                        <EditBenefitEnrollment enrollment={enrollment} onAfterSubmit={handleEditedEnrollment} adminEmail={userEmail} />
                                    </ModalContent>
                                </Modal>

                            </Box>) : null
                        }

                        <Typography variant="subtitle2" fontSize={'large'}>
                            {enrollment?.isWaived ? "Waived" : (enrollment?.carrierPlan?.productName || 'N/A')}
                        </Typography>
                        <Chip
                            label={enrollment?.carrierPlan?.benefitTypeName || 'N/A'}
                            color="primary"
                            size="small"
                            sx={{ width: 'fit-content' }}
                        />
                    </Stack>

                    <Stack sx={{ rowGap: {xs: 2, md: 0.5} }}>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row'}, gap: { xs: 1, md: 2 }, alignItems: {sx: 'center'} }}>
                            <Typography fontWeight='medium' variant="body2">
                                {translate('Enrollment Status:')}
                            </Typography>
                            <Chip
                                label={enrollment?.enrollmentStatus || 'N/A'}
                                color={enrollment?.enrollmentStatus === 'pending' ? 'warning' : 'success'}
                                size="small"
                                sx={{ maxWidth: 'fit-content' }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row'}, gap: { xs: 1, md: 2 }, alignItems: {sx: 'center'} }}>
                            <Typography fontWeight='medium' variant="body2" sx={{ marginRight: {md: '2ch'}, textWrap: 'nowrap' }}>
                                {translate('Enrollment Date:')}
                            </Typography>
                            <Typography variant="body2" sx={{  paddingLeft: {md: '4px'} }}>
                                {formatDate(enrollment?.enrollmentTimestamp)}
                            </Typography>
                        </Box>
                    </Stack>

                    <Box>
                        <Typography fontWeight='medium' variant="body2">
                            {translate('Plan Description:')}
                        </Typography>
                        <Divider sx={{ my: 1, borderBottomWidth: 'thin' }}/>
                        <Typography variant="body2">
                            {enrollment?.carrierPlan?.description || 'N/A'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography fontWeight='medium' variant="body2">
                            {translate('Dependents: ')}
                        </Typography>
                        <Divider sx={{ my: 1, borderBottomWidth: 'thin' }}/>
                        {enrollment?.dependents?.length > 0 ? (
                            <Stack spacing={1}>
                                {enrollment.dependents.map((dependent) => (
                                    <Box key={dependent?.dependentId}>
                                        <Typography variant="subtitle2">
                                            {dependent?.person?.firstName} {dependent?.person?.lastName}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {dependent?.relationshipType || 'Unknown'} • DOB: {formatDate(dependent?.person?.dateOfBirth)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                {translate('No Dependents')}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ mt: 'auto' }}>
                        <PlanSelectionSummaryButton onPlanDetailsClick={async () => {
                            setIsPlanDocumentModalOpen(true);
                            if (hasPlanId) {
                                await fetchDocuments();
                            }
                        }} />
                        {planType === "ebtoolkit" ? <PlanSummaryModalWithUrls
                            urls={planSummaryLink != null ? [planSummaryLink] : []}
                            open={isPlanDocumentModalOpen}
                            handleClose={() => {
                                setIsPlanDocumentModalOpen(false);
                            }}
                        /> : null }
                        {planType !== "ebtoolkit" && planType !== "hsa" ? <PlanSummaryModal isFetching={isFetching} open={isPlanDocumentModalOpen} documents={documents} handleClose={() => { setIsPlanDocumentModalOpen(false); }} /> : null }
                    </Box>
                </Box>

            </PlanSelectionPaper>
        </Grid>
    );
};