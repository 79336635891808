import React, { useState } from 'react';
import { useAssociateEmployersWithAgencyMutation } from '../../reducers/enrollmentApiSlice';
import AgencyRadioList from './AgencyRadioList';
import EmployerCheckboxList from "../Employer/EmployerCheckboxList";
import AuthTokenService from "../../services/AuthTokenService";
import {
    Button,
    Box,
    Typography,
    Alert,
    CircularProgress,
    Paper,
    Stepper,
    Step,
    StepLabel,
    useTheme,
    useMediaQuery
} from '@mui/material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import SingleEmployerSelector from "../Employer/SingleEmployerSelector";

const AssociateEmployersWithAgency = () => {
    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [associateEmployersWithAgency, { isLoading, isSuccess, isError, error }] = useAssociateEmployersWithAgencyMutation();
    const { user } = AuthTokenService.getAuthInfo();
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleEmployerSelect = (employer) => {
        setSelectedEmployer(employer);
    };

    const handleAgencySelect = (agency) => {
        setSelectedAgency(agency);
    };

    const handleSubmit = async () => {
        if (!selectedAgency || selectedEmployer === null) {
            alert(translate('Please select an agency and at least one employer.'));
            return;
        }

        const dto = {
            employerCodes: selectedEmployer === null ? [] : [selectedEmployer.employerCode],
            agencyCode: selectedAgency.agencyCode
        };

        await associateEmployersWithAgency(dto);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [
        translate('Select Agency'),
        translate('Select Employers'),
        translate('Review and Submit')
    ];

    return (
        <Paper elevation={3} sx={{ p: 3, m: 2 }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
                {translate('Associate Employers with Agency')}
            </Typography>

            <Stepper activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'} sx={{ mb: 4 }}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {activeStep === 0 && (
                <AgencyRadioList onAgencySelect={handleAgencySelect} />
            )}

            {activeStep === 1 && (
                <SingleEmployerSelector onEmployerSelect={handleEmployerSelect} email={user} />
            )}

            {activeStep === 2 && (
                <Box>
                    <Typography variant="h6">{translate('Selected Agency')}:</Typography>
                    <Typography>{selectedAgency?.agencyName}</Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>{translate('Selected Employers')}:</Typography>
                    <ul>
                        {selectedEmployer !== null ? (<li key={selectedEmployer.employerCode}>{selectedEmployer.employerName}</li>) : null}
                    </ul>
                </Box>
            )}

            {isError && <Alert severity="error" sx={{ mt: 2 }}>{error?.data?.message || translate('An error occurred while associating employers.')}</Alert>}
            {isSuccess && <Alert severity="success" sx={{ mt: 2 }}>{translate('Employers associated successfully with the agency!')}</Alert>}

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    variant="outlined"
                >
                    {translate('Back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                    disabled={isLoading || (activeStep === 0 && !selectedAgency) || (activeStep === 1 && selectedEmployer === null)}
                >
                    {isLoading ? <CircularProgress size={24} /> :
                        activeStep === steps.length - 1 ? translate('Submit') : translate('Next')}
                </Button>
            </Box>
        </Paper>
    );
};

export default AssociateEmployersWithAgency;