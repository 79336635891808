import {Box, Button, Chip, Grid, TextField, Typography, useTheme} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import EnrollmentStatusDropdown from "../EnrollmentStatusDropdown";
import {useUpdateEnrollmentByEmployeeBenefitEnrollmentIdMutation} from "../../reducers/enrollmentApiSlice";
import {Message, MessageType} from "../../types/message";
import MessageList from "../MessageList";

export const EditBenefitEnrollment = ({ enrollment, onAfterSubmit, adminEmail }) => {
    useTheme();
    const { translate } = useCustomTranslation();

    const [selectedEnrollmentStatus, setSelectedEnrollmentStatus] = useState(enrollment?.enrollmentStatus ?? '');
    const [updateEnrollmentByEmployeeBenefitEnrollmentId] = useUpdateEnrollmentByEmployeeBenefitEnrollmentIdMutation();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        setSelectedEnrollmentStatus(enrollment?.enrollmentStatus ?? '');
    }, [enrollment]);

    const handleEnrollmentStatusChange = (enrollmentStatus) => {
        setSelectedEnrollmentStatus(enrollmentStatus.enrollmentStatusName ?? '');
    };

    const handleSave = async () => {
        try {
            await updateEnrollmentByEmployeeBenefitEnrollmentId({
                employeeBenefitEnrollmentId: enrollment?.id,
                enrollmentStatus: selectedEnrollmentStatus,
                changedBy: adminEmail,
                accountEmail: adminEmail
            }).unwrap();
            setMessages([new Message('Saved!', MessageType.Success)]);
            onAfterSubmit();
        } catch (e) {
            console.error('Error when saving the modified enrollment:', e);
            setMessages([new Message('Unexpected Error! Please try again.', MessageType.Error)]);
        }
    };

    return (
        <>
        <Grid container sx={{ gap: 2, marginBottom: 2}}>

            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <MessageList messages={messages} onClose={() => { setMessages([]); }} />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    disabled={true}
                    label={'Plan Name'}
                    value={enrollment?.isWaived ? "Waived" : (enrollment?.carrierPlan?.productName || 'N/A')}
                />
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ maxWidth: 'fit-content', minWidth: '170px' }}>
                    <EnrollmentStatusDropdown value={selectedEnrollmentStatus} onChange={handleEnrollmentStatusChange} />
                </Box>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        slotProps={{ field: { clearable: true } }}
                        label="Enrollment Date"
                        value={dayjs(enrollment?.enrollmentTimestamp)}
                        disabled={true}
                        sx={{ maxWidth: 'fit-content' }}
                    />
                </LocalizationProvider>
            </Grid>


        </Grid>

        <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginTop: 'auto' }}
            onClick={ () => {handleSave();} }
        >
            {translate('Save')}
        </Button>
        </>
    );
}