import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import {useGetAllEnrollmentStatusesQuery, useGetGendersQuery} from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";
import LoadingSpinner from "./LoadingSpinner";

function EnrollmentStatusDropdown({ value, onChange, id = '' }) {
    const theme = useTheme();
    const { data: enrollmentStatuses = [], isLoading } = useGetAllEnrollmentStatusesQuery();
    const { translate } = useCustomTranslation();

    const handleChange = (e) => {
        const selectedEnrollmentStatusName = e.target.value;
        let selectedEnrollmentStatus = enrollmentStatuses.find(es => es.enrollmentStatusName === selectedEnrollmentStatusName)
        onChange(selectedEnrollmentStatus);
    };

    if (isLoading) {
        return <LoadingSpinner aria-busy="true" aria-label={translate('Loading enrollment statuses...')} />;
    }

    return (
        <FormControl fullWidth>
            <InputLabel
                id={`enrollment-status-select${id}`}
                style={{ color: theme.palette.text.primary }}
            >
                {translate('Enrollment Status')}
            </InputLabel>
            <Select
                labelId={`label-enrollment-status-select${id}`}
                id={`enrollment-status-select${id}`}
                value={value}
                label={translate('Enrollment Status')}
                onChange={handleChange}
                aria-labelledby={`enrollment-status-select${id}`}
                style={{ backgroundColor: theme.palette.background.paper }}  // Explicitly using theme background
            >
                {enrollmentStatuses.map(enrollmentStatus => (
                    <MenuItem key={enrollmentStatus.enrollmentStatusName} value={enrollmentStatus.enrollmentStatusName}>
                        {translate(enrollmentStatus.enrollmentStatusName)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default EnrollmentStatusDropdown;
