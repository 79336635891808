import React, {useEffect, useMemo, useState} from 'react';
import {useGetAllEmployersQuery} from '../../reducers/enrollmentApiSlice';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    CircularProgress,
    FormControlLabel,
    FormGroup, Radio,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmployerSearchBar from "./EmployerSearchBar";
import EmployerPagination from "./EmployerPagination";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {AlertTitle} from "@mui/lab";

const SingleEmployerSelector = ({ email, onEmployerSelect }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        data: employers = [],
        error: allEmployersError,
        isLoading: allEmployersLoading
    } = useGetAllEmployersQuery(email);

    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [expanded, setExpanded] = useState(false);
    const employersPerPage = 10;

    useEffect(() => {
        onEmployerSelect(selectedEmployer);
    }, [selectedEmployer, onEmployerSelect]);

    const filteredEmployers = useMemo(() =>
            employers.filter((employer) =>
                employer.employerName.toLowerCase().includes(searchQuery.toLowerCase())
            ),
        [employers, searchQuery]
    );

    const totalPages = Math.ceil(filteredEmployers.length / employersPerPage);
    const paginatedEmployers = useMemo(() =>
            filteredEmployers.slice(
                (currentPage - 1) * employersPerPage,
                currentPage * employersPerPage
            ),
        [filteredEmployers, currentPage, employersPerPage]
    );

    const handleRadioChange = (employer) => {
        setSelectedEmployer((prevSelected) => {
            const isSelected = prevSelected?.employerId === employer.employerId;
            if (isSelected) {
                return null;
            } else {
                return employer;
            }
        });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    if (allEmployersLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress aria-label={translate('Loading employers')} />
            </Box>
        );
    }

    if (allEmployersError) {
        return <Alert severity="error">{translate('Error loading employers')}: {allEmployersError.message}</Alert>;
    }

    return (
        <Box sx={{ mt: 2, mx: 2 }}>
            <Accordion expanded={expanded} onChange={handleAccordionChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="employer-list-content"
                    id="employer-list-header"
                >
                    <Typography variant={isMobile ? "subtitle1" : "h6"}>{translate('List of Employers')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {employers.length === 0 ? (
                        <Alert sx={{ width: '100%' }} severity="warning">
                            <AlertTitle>{translate('No Companies Available')}</AlertTitle>
                            {translate('It looks like you don’t have any companies assigned, so this content can’t be loaded. Please contact your system administrator to get access.')}
                        </Alert>
                    ) : (
                        <>
                            <EmployerSearchBar
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                placeholder={translate('Search employers')}
                            />
                            {filteredEmployers.length === 0 ? (
                                <Alert sx={{ width: '100%', mt: 2 }} severity="info">
                                    <AlertTitle>{translate('No Matching Results')}</AlertTitle>
                                    {translate('No employers match your search. Please try a different search term.')}
                                </Alert>
                            ) : (
                                <>
                                    <FormGroup>
                                        {paginatedEmployers.map((employer) => (
                                            <FormControlLabel
                                                key={employer.employerId}
                                                control={
                                                    <Radio
                                                        checked={selectedEmployer?.employerId === employer.employerId}
                                                        onClick={() => handleRadioChange(employer)}
                                                        value={employer.employerName}
                                                        name={employer.employerName}
                                                        inputProps={{ 'aria-label': `${translate('Select')} ${employer.employerName}` }}
                                                    />
                                                }
                                                label={employer.employerName}
                                            />
                                        ))}
                                    </FormGroup>
                                    <EmployerPagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                    />
                                </>
                            )}
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

SingleEmployerSelector.propTypes = {
    email: PropTypes.string.isRequired,
    onEmployerSelect: PropTypes.func.isRequired,
};

export default SingleEmployerSelector;
